import React, {
  FC,
  useState,
  useRef,
  useEffect,
  ReactNode,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Image, Form, message, Drawer, Checkbox } from "antd";
import { useParams, useHistory, Link } from "react-router-dom";
import { CkCheckbox, CkButton } from "../../CkUI";

//WindowSize
import {
  gtmAddLayer,
  getImageBaseUrl,
  useResizeObserver,
  tierCodeToDesc,
} from "../../utilities";
import {
  CKCloseIcon,
  CkCaretDownIcon,
  CkReloadIcon,
  CkContinueCurveIcon,
  CkReturnCurveIcon,
} from "../../assets/SvgIcons";

import { WorkshopPublicDetail, Vehicle, WorkshopServiceType, IServiceCategory, IPackageCategory, WorkshopService, WorkshopServiceTier, PackageCodeType } from "../../models";
import {
  EndConsumerRequestAPI,
  WorkshopAPI,
  UserAPI,
  VehicleAPI,
  ConfigurationsAPI,
} from "../../services/axios";
//Context
import store, {
  getGlobalLayout,
  // getPublicPackages,
  fetchPackageTypes,
  getSasToken,
  getContactWorkshopId,
  closeContactDrawer,
  getUser,
  getFinderPackagesCatalog,
  getFinderServicesCatalog,
} from "../../stores";

//utilities
import Compressor from "compressorjs";
import { BlobServiceClient } from "@azure/storage-blob";

import AttachmentsMap from "./AttachmentsMap";
import VideoPlayerModal from "./VideoPlayerModal";
import AudioModal from "./AudioModal";
import LocaleAttachmentsMap from "./LocaleAttachmentsMap";
import UserInput from "./UserInput";
import CreateAccountCTA from "./CreateAccountCTA";
import { WheelLoader } from "../../components/Loaders";

import "./styles.css";
import { CloseOutlined } from "@ant-design/icons";

export const addInteractionLoadingEvent = (handleInteractionLoadingEvent) => {
  document.addEventListener(
    interactionLoadingEvent,
    handleInteractionLoadingEvent
  );
};

export const removeInteractionLoadingEvent = (
  handleInteractionLoadingEvent
) => {
  document.removeEventListener(
    interactionLoadingEvent,
    handleInteractionLoadingEvent
  );
};

/**
 * This
 * @param {
 *  history: React Router Dom History Object,
 *  openCb: Callback to open Drawer
 *  workshopId: Workshop id to open
 * }
 */
export const openContactWorkshop = ({
  history,
  openCb,
  workshopId,
}: {
  history: any;
  openCb: Function;
  workshopId: string | number;
}) => {
  const state = store.getState();
  const {
    publicCatalog: { globalLayout },
  } = state;
  if (globalLayout === "mobilhub") {
    history.push(`/taller/${workshopId}/contacto`);
  } else {
    openCb();
  }
};

const VehiclePlaceholder = getImageBaseUrl("vehiclePlaceholder.png");

export type TAnimationsDelay =
  | "p5"
  | "1"
  | "1p5"
  | "2"
  | "2p5"
  | "3"
  | "3p5"
  | "4"
  | "4p5"
  | "5";
export const userInputEvent = "userInputEvent";
export const interactionLoadingEvent = "interactionLoadingEvent";
export type TExpectedUserInput =
  | "message"
  | "vehicle"
  | "name"
  | "telephone"
  | "email"
  | "terms"
  | undefined;
type TContactType = "quotation" | "query" | undefined;
type TCriteria = "packages" | "services" | undefined;
interface IVehicle extends Vehicle {
  isCustomModel: boolean;
  isCustomBrand: boolean;
  isNewVehicle: boolean;
  isDefaultVehicle: boolean;
  nickname: string;
  newNickname: string;
  typeCode: string;
  brandCode: string;
  customBrandCode: string;
  brandName: string;
  yearCode: string;
  modelCode: string;
  customModel: string;
  modelName: string;
  vehicleId: number;
  licensePlate: string;
}
export interface IUser {
  name: string;
  lastName: string;
  email: string;
  phone: string;
  id: string;
}

export interface IRequestResume {
  endConsumer: IUser;
  workshop: WorkshopPublicDetail;
  contactType: TContactType;
  loggedUser: boolean;
}
interface IProps {
  displayAs?: "page" | "drawer";
}

const WorkshopContact: FC<IProps> = ({
  displayAs = "drawer"
}) => {
  const dispatch = useDispatch();
  const packagesCategories = useSelector(getFinderPackagesCatalog);
  const servicesCategories = useSelector(getFinderServicesCatalog);
  const [selectedType, setSelectedType] = useState<WorkshopServiceType>("MAN"); // Service type - True: "services", False: "packages";
  const [selectedCategory, setSelectedCategory] = useState<
    IPackageCategory | IServiceCategory
  >();
  const [availablePackages, setAvailablePackages] = useState<WorkshopService[]>(
    []
  );
  const [availableServices, setAvailableServices] = useState<WorkshopService[]>(
    []
  );
  const [availablePackagesCategories, setAvailablePackagesCategories] =
    useState<IPackageCategory[]>([]);
  const [availableServicesCategories, setAvailableServicesCategories] =
    useState<IServiceCategory[]>([]);

  const workshopIdProp = useSelector(getContactWorkshopId)
  const [isVisible, setIsVisible] = useState<boolean>(workshopIdProp !== undefined);
  useEffect(() => {
    setIsVisible(workshopIdProp !== undefined);
  }, [workshopIdProp]);

  useEffect(() => {
    if (availablePackages && packagesCategories.values.length > 0) {
      const availableCategories = packagesCategories.values.filter(
        (packageCategory: IPackageCategory) =>
          availablePackages.some(
            (pack) =>
              pack.servicePackageCode === packageCategory.servicePackageCode
          )
      );
      if (availableCategories.length === 0) {
        /* 
        form.setFieldsValue({
          service: {
            "service-type": "REP",
          },
        }); 
        */
        setSelectedType("REP");
      }
      setAvailablePackagesCategories(availableCategories);
    }
  }, [availablePackages, packagesCategories]);

  useEffect(() => {
    if (availableServices && servicesCategories.values.length > 0) {
      const availableCategories = servicesCategories.values.filter((service) => !!service === true).filter(
        (serviceCategory: IServiceCategory) => {
          return availableServices.some(
            (service) =>
              service.srvcTypeCode === serviceCategory.serviceTypeCode
          );
        }
      );
      if (availableCategories.length === 0) {
        /* 
        form.setFieldsValue({
          service: {
            "service-type": "MAN",
          },
        }); 
        */
        setSelectedType("MAN");
      }
      setAvailableServicesCategories(availableCategories);
    }
  }, [availableServices, servicesCategories]);

  /**
   * States and refs
   */
  const headerRef = useResizeObserver<HTMLDivElement>(() => {
    updatePaddings({
      scrollMessages: false,
    });
  });
  const footerRef = useResizeObserver<HTMLDivElement>(() => {
    updatePaddings({
      scrollMessages: false,
    });
  });
  const wrapperRef = useResizeObserver<HTMLDivElement>((element, entry) => {
    window.setTimeout(() => {
      const nodes = element.querySelectorAll(".flow-message");
      if (nodes.length > 0) {
        const nodeRect = nodes[nodes.length - 1].getBoundingClientRect();
        element.scrollTo({
          top:
            nodeRect.y > element.scrollTop
              ? nodeRect.y - 10
              : element.scrollHeight,
          behavior: "smooth",
        });
      } else {
        element.scrollTo({
          top: element.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 100);
  });

  const contactData = useRef<{
    workshop: WorkshopPublicDetail | undefined;
    type: TContactType;
    message: string;
    criteria: TCriteria;
    selectedPackage: any;
    selectedPackageTier: any;
    selectedService: any;
    availablePackages: any[];
    availableServices: any[];
    vehicleText: string;
    vehicle: IVehicle | undefined;
    userVehicles: IVehicle[];
    user: IUser | undefined;
    packageTypes: any[];
    fileList: any[];
  }>({
    workshop: undefined,
    type: undefined,
    message: "",
    criteria: undefined,
    selectedPackage: undefined,
    selectedPackageTier: undefined,
    selectedService: undefined,
    availablePackages: [],
    availableServices: [],
    vehicleText: "",
    vehicle: undefined,
    userVehicles: [],
    user: undefined,
    packageTypes: [],
    fileList: [],
  });

  const sasToken = useSelector(getSasToken);
  const globalLayout = useSelector(getGlobalLayout);
  // const packageTypes = useSelector(getPublicPackages);

  const containerName = "up-images-container";
  const account = process.env.REACT_APP_STORAGEACCOUNT as string;
  const blobService = useRef<any>(null);
  const containerClient = useRef<any>();

  const history = useHistory();
  const { workshopId } = useParams<{ workshopId: string }>();

  const [workshop, setWorkshop] = useState<WorkshopPublicDetail | undefined>(
    undefined
  );

  const [interactions, setInteractions] = useState<any[]>([]);
  const [_, setInteractionsHistory] = useState<any[]>([]);
  // const [loadingInteraction, triggerLoading] = useState<boolean>(false);
  const [enableSend, setEnableSend] = useState<boolean>(false);
  const [messageSent, setMessageSent] = useState<boolean>(false);

  /**
   * States
   */
  const filesUploaded = useRef<any[]>([]);
  const componentWrapper = useRef<HTMLDivElement>();

  //Message Form
  const [messageForm] = Form.useForm();

  //User Context
  const user = useSelector(getUser);

  //Files
  const [fileList, setFileList] = useState<any[]>([]);
  const [audioVisible, setAudioVisible] = useState<boolean>(false);

  const [videoUrl, setVideoUrl] = useState<string>("");
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [requestResume, setRequestResume] = useState<IRequestResume>();

  const [expectedUserInput, setExpectedUserInput] =
    useState<TExpectedUserInput>();

  /**
   * Functions
   */

  /**
   * Add a event listener that will be triggered on userInputEvent that is a custom event
   * @param handleUserInputEvent function to trigger on user input event
   * @returns void
   */
  const addUserInputListener = (handleUserInputEvent) =>
    document.addEventListener(userInputEvent, handleUserInputEvent);
  /**
   * Remove a event listener that will be triggered on userInputEvent that is a custom event
   * @param handleUserInputEvent function to trigger on user input event
   * @returns void
   */
  const removeUserInputListener = (handleUserInputEvent) =>
    document.removeEventListener(userInputEvent, handleUserInputEvent);

  const getContainer = () =>
    window.innerWidth > 768
      ? document.querySelector(".page-container") || document.body
      : document.body;

  const cleanData = () => {
    setInteractions([]);
    setExpectedUserInput(undefined);
    setInteractionsHistory([]);
    setFileList([]);
    filesUploaded.current = [];
  };

  const close = () => {
    if (displayAs === "page") {
      history.goBack();
    }
    dispatch(closeContactDrawer());
    cleanData();
  };

  const scrollBottom = () => {
    if (
      componentWrapper.current === null ||
      componentWrapper.current === undefined
    )
      return;
    const scrollArea = componentWrapper.current.querySelector(
      ".interations-wrapper"
    ) as HTMLElement;
    if (scrollArea == null) return;

    scrollArea.scrollTo({
      top: scrollArea.scrollHeight,
      behavior: "smooth",
    });
  };

  const triggerLoading = (newValue: boolean) => {
    const customEvent = new CustomEvent(interactionLoadingEvent, {
      detail: newValue,
    });
    document.dispatchEvent(customEvent);
  };

  /**
   * @params {
   *  scrollMessages: boolean - If it's true, after calculate height messages-scroll will be scrolled to bottom.
   * }
   */
  const updatePaddings = ({
    scrollMessages = false,
  }: {
    scrollMessages: boolean;
  }) => {
    if (
      componentWrapper.current === null ||
      componentWrapper.current === undefined
    )
      return;
    const headerHeight = headerRef.current
      ? headerRef.current?.getBoundingClientRect()?.height || 0
      : 0;
    const footerHeight = footerRef.current
      ? footerRef.current?.getBoundingClientRect()?.height || 0
      : 0;
    componentWrapper.current.style.padding = `${headerHeight}px 0px ${footerHeight}px 0px`;
    scrollMessages && scrollBottom();
  };

  const postNewMessage = async () => {
    triggerLoading(true);
    // Check if email is workshop email
    if (
      user === undefined ||
      user === null ||
      (!!user && user.email !== contactData.current.user.email)
    ) {
      const userCheckResult = await UserAPI.checkUser(
        contactData.current.user.email
      );
      if (userCheckResult.data.isWSUser) {
        message.error("Este correo ya fue registrado como taller");
        triggerLoading(false);
        pushInteraction(<RequestUserEmail />);
        return false;
      }
    }

    const uploaded: Array<any> = filesUploaded.current;
    const dateTime = new Date();
    const requestData = {
      id: "0",
      ...(contactData.current.type === "query"
        ? {
            statusCode: "PEND",
            typeCode: "REQUEST",
            serviceTypes: [],
            packages: [],
          }
        : {}),
      ...(contactData.current.type === "quotation"
        ? {
            typeCode: "QUOTE",
            createdDate: dateTime.toISOString(),
            lastMessage: dateTime.toISOString(),
            serviceTypes: [],
            packages: [],
            customService: undefined,
            customServiceTier: undefined
          }
        : {}),
      statusCode: "PEND",
      createdDate: dateTime.toISOString(),
      lastMessage: dateTime.toISOString(),

      ...(contactData.current.vehicle
        ? {
            vehicle: {
              endConsumerVehicleId: contactData.current.vehicle
                .endConsumerVehicleId
                ? contactData.current.vehicle.endConsumerVehicleId
                : 0,
              nickname: contactData.current.vehicle.nickname,
              typeCode: contactData.current.vehicle.typeCode,
              typeName: contactData.current.vehicle.typeCode,
              brandCode: contactData.current.vehicle.brandCode,
              brandName: contactData.current.vehicle.brandName,
              IsCustomBrand: contactData.current.vehicle.isCustomBrand,
              yearCode: contactData.current.vehicle.yearCode,
              year: contactData.current.vehicle.yearCode,
              modelCode: contactData.current.vehicle.modelCode,
              modelName: contactData.current.vehicle.modelName,
              isCustomModel:
                contactData.current.vehicle.isCustomBrand === true
                  ? true
                  : contactData.current.vehicle.isCustomModel,
              isEnabled: true,
              vehicleId: contactData.current.vehicle.vehicleId,
            },
          }
        : {}),
      endConsumer: {
        // ...contactData.current.user,
        name: contactData.current.user.name,
        lastName: contactData.current.user.lastName,
        email: contactData.current.user.email,
        phone: contactData.current.user.phone,
        ...(user ? { id: user.id } : {}),
      },
      messages: [
        {
          IsWorkshopMessage: false,
          Message:
            contactData.current.vehicleText !== ""
              ? `Vehículo: ${contactData.current.vehicleText} \n\n ${contactData.current.message}`
              : contactData.current.message,
          CreatedDate: dateTime.toISOString(),
          Attachments: uploaded,
        },
      ],
      workshop: { id: contactData.current.workshop.id },
    };

    // Prepare packages
    if (
      contactData.current.type === "quotation" &&
      contactData.current.criteria === "packages"
    ) {
      requestData.customService = contactData.current.selectedPackage.workshopServiceId;
      requestData.customServiceTier = contactData.current.selectedPackageTier.servicePackageTypeCode;
    }

    // Prepare services
    if (
      contactData.current.type === "quotation" &&
      contactData.current.criteria === "services"
    ) {
      requestData.customService = contactData.current.selectedService.workshopServiceId;
    }


    // Post files
    await postFiles();
    requestData.messages[0].Attachments = filesUploaded.current;
    const messageRequest = await EndConsumerRequestAPI.addRequest(requestData)
      .then((requestResponse) => requestResponse.data)
      .catch((err) => undefined);
    if (messageRequest === undefined) {
      triggerLoading(false);
      pushInteraction(
        <FlowMessage label={"Se produjo un error al enviar su mensaje."} />
      );
      if (!!user === false) {
        pushInteraction(<RequestUserPhone />);
      } else {
        if (contactData.current.type === "query") {
        } else {
          pushInteraction(<ChooseVehicleFromFavorites />);
        }
      }
      return;
    }
    gtmAddLayer({
      User_ID: contactData.current.user?.id,
      account_type: "Customer",
      event: "user_message",
    });

    pushInteraction(
      <FlowMessage
        label={
          "¡Tu mensaje fue enviado con éxito!  Muy ponto recibirás respuesta de este taller."
        }
      />
    );
    setExpectedUserInput(undefined);
    setMessageSent(true);
    triggerLoading(false);
    setRequestResume({
      endConsumer: requestData.endConsumer as IUser,
      workshop: contactData.current.workshop,
      contactType: contactData.current.type,
      loggedUser: !!user,
    });
  };

  const getVehicles = () => {
    VehicleAPI.get()
      .then((values) => {
        contactData.current.userVehicles = (values.data as IVehicle[]) || [];
      })
      .catch(() => {
        contactData.current.userVehicles = [];
      });
  };

  const getWorkshop = async (payload: {
    id: string;
    coordinates: string;
    userTimeZone: string;
  }) => {
    setWorkshop(undefined);
    const workshopRequest = await WorkshopAPI.getById(
      parseInt(payload.id),
      payload
    )
      .then((response) => response.data)
      .catch(() => undefined);
    if (workshopRequest === undefined) {
      displayAs === "page" && history.replace("/404");
      return;
    }

    await dispatch(fetchPackageTypes()).then((response: any) => {
      const { payload } = response;
      contactData.current.packageTypes = payload;
    });

    const _availablePackages = (workshopRequest.customServices || []).filter(
      (service) => service.workshopServiceType === "MAN"
    );
    const _availableServices = (workshopRequest.customServices || []).filter(
      (service) => service.workshopServiceType === "REP"
    );
    setAvailablePackages(_availablePackages);
    setAvailableServices(_availableServices);
    contactData.current.availablePackages = _availablePackages;
    contactData.current.availableServices = _availableServices;
    /* 
    contactData.current.availablePackages = (workshopRequest.packages || []).reduce(
      (prev: any, curr: any) => {
        const array = prev;
        const code = curr.servicePackageSet.servicePackageCode;
        const index = array.findIndex((a: any) =>
          Object.keys(a).includes(code)
        );
        const item = {
          type: curr.servicePackageSet?.servicePackageTypeCode,
          typeDesc:
            curr.servicePackageSet.servicePackageType?.servicePackageTypeDesc,
          price: curr.price,
          description: curr.servicePackageSet?.servicePackageMap,
          comment: curr.comment,
          servicePackageSetId: curr.servicePackageSetId,
          warnings: curr.servicePackageSet?.servicePackageWarnings,
        };
        if (index < 0) {
          array.push({ [code]: [item] });
        } else {
          const set = { ...array[index] };
          set[code] = [...set[code], item];
          array.splice(index, 1, set);
        }
        return array;
      },
      []
    );

    const workshopServices = await WorkshopAPI.getServicesTypes(
      workshopRequest.id
    )
      .then((response) => response.data)
      .catch(() => []);
    workshopRequest.services = workshopServices;
    contactData.current.availableServices = workshopServices;
    */

    contactData.current.workshop = workshopRequest;
    setWorkshop(workshopRequest);
    initializeFlow();
  };

  // Get Package name
  const getPackageName = (code: string) => {
    if (code) {
      const pack = contactData.current.packageTypes.find(
        (a: any) => a.servicePackageCode === code
      );
      return pack;
    } else {
      return "";
    }
  };

  const getPackageValue = (code: string) => {
    const pack = contactData.current.packageTypes.find(
      (a: any) => a.servicePackageCode === code
    );
    return pack;
  };

  const uploadFileAndCompress = async (file: any) => {
    return new Promise(async (resolve, reject) => {
      let resp: any[] = filesUploaded.current;
      let date = new Date();
      let fileName = `${date.getTime()}_${file.name}`;
      const blockBlobClient =
        containerClient.current.getBlockBlobClient(fileName);

      try {
        new Compressor(file, {
          quality: 0.6,
          convertTypes:
            "image/png, image/webp, image/gif, image/tiff, image/apng, image/ico, image/cur, image/ai, image/svg, image/raw, image/jfif",
          convertSize: 3000000,
          async success(result) {
            await blockBlobClient.upload(result, result.size);

            let attached = {
              fileName: file.name,
              url: blockBlobClient.url.split("?")[0],
              messageId: "0",
              id: "0",
            };

            resp.push(attached);
            filesUploaded.current = resp;
            resolve(attached);
          },
        });
      } catch (error) {
        console.error(error);
        resolve(null);
      }
    });
  };

  const uploadFile = async (file: any) => {
    return new Promise(async (resolve, reject) => {
      let resp: any[] = filesUploaded.current;
      let date = new Date();
      let fileName = `${date.getTime()}_${file.name}`;
      const blockBlobClient =
        containerClient.current.getBlockBlobClient(fileName);

      try {
        await blockBlobClient.upload(file, file.size);
        let attached = {
          fileName: file.name,
          url: blockBlobClient.url.split("?")[0],
          messageId: "0",
          id: "0",
        };

        resp.push(attached);
        filesUploaded.current = resp;
        resolve(attached);
      } catch (error) {
        console.error(error);
        resolve(null);
      }
    });
  };

  const postFiles = async () => {
    await ConfigurationsAPI.getSasToken().then((values) => {
      const sasToken = values.data;
      blobService.current = new BlobServiceClient(`${account}?${sasToken}`);
      containerClient.current =
        blobService.current.getContainerClient(containerName);
    });

    await Promise.all(
      contactData.current.fileList.map(async (file: any, id: number) => {
        if (file.file.type.toString().startsWith("image/")) {
          await uploadFileAndCompress(file.file);
        } else {
          await uploadFile(file.file);
        }
      })
    );
  };

  const previousInteration = () => {
    setInteractionsHistory((prevHistory) => {
      if (prevHistory.length === 0) {
        return prevHistory;
      } else {
        const lastElement = prevHistory.slice(-1);
        lastElement &&
          pushInteraction(lastElement[0], {
            direction: "backward",
          });
        prevHistory.pop();
        return prevHistory;
      }
    });
  };

  const pushHistory = (newHistory: any) => {
    setInteractionsHistory((prevHistory) => {
      return [...prevHistory, newHistory];
    });
  };

  const pushInteraction = (
    newInteraction: any,
    options: {
      reset?: boolean;
      direction?: "forward" | "backward";
    } = {
      reset: false,
      direction: "forward",
    }
  ) => {
    if (options.reset) {
      setExpectedUserInput(undefined);
      setInteractionsHistory([]);
      setFileList([]);
      filesUploaded.current = [];
    }
    setInteractions((prevInteractions) => {
      options.direction === "forward" &&
        options.reset === false &&
        prevInteractions.slice(-1)[0] !== undefined &&
        pushHistory(prevInteractions.slice(-1)[0]);
      return [...(options.reset ? [] : prevInteractions), newInteraction];
    });
  };

  const initializeFlow = () => pushInteraction(<SelectContactType />, {
    reset: true
  });

  /**
   * Request user to choose the contact type
   */
  const SelectContactType: FC<{}> = () => {
    const [selected, setSelected] = useState<TContactType>();
    const [disabled, setDisabled] = useState<boolean>(false);

    useEffect(() => {
      selected !== undefined && setDisabled(true);
    }, [selected]);

    return (
      <>
        <FlowMessage label={"¿Qué te gustaría preguntarle al taller?"} />
        <UserOptions delay={"p5"}>
          <Button
            disabled={disabled}
            className={selected === "quotation" ? "active" : ""}
            onClick={() => {
              setSelected("quotation");
              contactData.current.type = "quotation";
              pushInteraction(<InitQuotationFlow />);
            }}
          >
            Estimación de precio
          </Button>
          <Button
            disabled={disabled}
            className={selected === "query" ? "active" : ""}
            onClick={() => {
              setSelected("query");
              contactData.current.type = "query";
              pushInteraction(<InitQueryFlow />);
            }}
          >
            Duda general
          </Button>
        </UserOptions>
      </>
    );
  };

  /* 
  ==========================================================================
                              Flows Initializers
  ========================================================================== 
  */
  /**
   * Request user to choose if want to estimate package or service
   */
  const InitQuotationFlow: FC<{}> = () => {
    const [selected, setSelected] = useState<TCriteria>();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [availability, setAvailability] = useState<{
      packages: boolean;
      services: boolean;
    }>({
      packages: false,
      services: false,
    });

    useEffect(() => {
      selected !== undefined && setDisabled(true);
    }, [selected]);

    useEffect(() => {
      setAvailability({
        packages: contactData.current.availablePackages.length > 0,
        services: contactData.current.availableServices.length > 0
      });
    }, []);

    return (
      <>
        <FlowMessage delay={"p5"} label={"Quiero estimar el precio para:"} />
        <UserOptions delay={"1"}>
          <Button
            disabled={disabled || availability.packages === false}
            className={[
              ...(selected === "packages" ? ["active"] : []),
              ...(availability.packages === false ? ["not-available"] : []),
            ].join(" ")}
            onClick={() => {
              setSelected("packages");
              contactData.current.criteria = "packages";
              pushInteraction(<RequestUserPackage />);
            }}
          >
            Mantenimiento
          </Button>
          <Button
            disabled={disabled || availability.services === false}
            className={[
              ...(selected === "services" ? ["active"] : []),
              ...(availability.services === false ? ["not-available"] : []),
            ].join(" ")}
            onClick={() => {
              setSelected("services");
              contactData.current.criteria = "services";
              pushInteraction(<RequestUserService />);
            }}
          >
            Reparación
          </Button>
        </UserOptions>
        {disabled === false && (
          <InteractionOptions
            delay={"1p5"}
            onGoBack={() => setDisabled(true)}
          />
        )}
      </>
    );
  };

  /**
   * Request users for their messages, or what they want to ask
   * Note: states defined outside the component does not trigger the useEffects
   *       thats why we are using custom events
   */
  const InitQueryFlow: FC<{}> = ({}) => {
    const [disabled, setDisabled] = useState<boolean>(false);
    const [response, setResponse] = useState<string>();

    const callNextStep = () => pushInteraction(<RequestUserName />);

    const disableUserInput = () => {
      setDisabled(true);
      removeUserInputListener(handleUserInputEvent);
      setExpectedUserInput(undefined);
    };

    // This function will listen the userInput when is triggered
    const handleUserInputEvent = (event: CustomEvent) => {
      triggerLoading(true);
      const userResponse = event.detail;
      disableUserInput();
      contactData.current.message = userResponse.toString();
      setResponse(userResponse);
      triggerLoading(false);
      postFiles(); // delete this
      callNextStep();
    };

    useEffect(() => {
      setExpectedUserInput("message");
      addUserInputListener(handleUserInputEvent);
      return () => {
        removeUserInputListener(handleUserInputEvent);
      };
    }, []);

    return (
      <>
        <FlowMessage
          delay={"p5"}
          label={"Escribe tu duda en el campo de mensaje"}
        />
        {disabled === false && (
          <InteractionOptions delay={"1"} onGoBack={() => disableUserInput()} />
        )}
        {response && (
          <>
            <UserMessage label={"Tu duda:"} text={response} />
            <AttachmentsMap
              attached={contactData.current.fileList}
              setVideoUrl={setVideoUrl}
            />
          </>
        )}
      </>
    );
  };
  /* 
  ==========================================================================
                              /Flows Initializers
  ========================================================================== 
  */
  /* 
  ==========================================================================
                          Choose Package and tiers
  ========================================================================== 
  */
  /**
   * Request user to choose a package
   */
  const RequestUserPackage: FC<{}> = () => {
    const [selected, setSelected] = useState<WorkshopService>();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [availablePackages, setAvailablePackages] = useState<WorkshopService[]>([]);

    useEffect(() => {
      selected !== undefined && setDisabled(true);
    }, [selected]);

    
    useEffect(() => {
      setAvailablePackages(contactData.current.availablePackages);
    }, []); 

    return (
      <>
        <FlowMessage delay={"p5"} label={"¿Que mantenimiento necesitas?"} />
        <UserOptions flexDirection="column" delay={"1"}>
          {availablePackages.map((code) => (
            <Button
              key={code.workshopServiceId}
              disabled={disabled}
              className={selected && code.workshopServiceId === selected.workshopServiceId ? "active" : ""}
              onClick={() => {
                setSelected(code);
                contactData.current.selectedPackage = code;
                pushInteraction(<RequestUserPackageTier />);
              }}
            >
              {code.workshopServiceName}
            </Button>
          ))}
        </UserOptions>
        {disabled === false && (
          <InteractionOptions
            delay={"1p5"}
            onGoBack={() => setDisabled(true)}
          />
        )}
      </>
    );
  };

  /**
   * Request user to choose a package tier
   */
  const RequestUserPackageTier: FC<{}> = () => {
    const [selected, setSelected] = useState<string>("");
    const [expand, setExpand] = useState<string>("");
    const [disabled, setDisabled] = useState<boolean>(false);
    const [availablePackagesTiers, setAvailablePackagesTiers] = useState<WorkshopServiceTier[]>(
      []
    );

    const toogleDetails = (newValue: string) =>
      setExpand((prevValue) => (prevValue === newValue ? "" : newValue));

    useEffect(() => {
      if (selected !== "") {
        setDisabled(true);
        setExpand("");
      }
    }, [selected]);

    useEffect(() => {
      setAvailablePackagesTiers(
        contactData.current.selectedPackage.serviceTiers
      );
    }, []);
    return (
      <>
        <FlowMessage delay={"p5"} label={"¿Qué paquete te interesa?"} />
        <UserOptions flexDirection="column" delay={"1"}>
          {availablePackagesTiers.map((packageTier) => (
            <div className="package-tier-container" key={packageTier.servicePackageTypeCode}>
              <Button
                disabled={disabled}
                className={selected === packageTier.servicePackageTypeCode ? "active" : ""}
                onClick={() => {
                  setDisabled(true);
                  setSelected(packageTier.servicePackageTypeCode);
                  contactData.current.selectedPackageTier = packageTier;
                  pushInteraction(<RequestIfHaveAnyQuestion />);
                }}
              >
                {tierCodeToDesc(packageTier.servicePackageTypeCode as PackageCodeType)}
              </Button>
              <div className="tier-price-container">
                {packageTier.servicePackageTypeCodeBasePrice && (
                  <p className="package-description-price">
                    <span>
                      {new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 0,
                      }).format(packageTier.servicePackageTypeCodeBasePrice)}
                    </span>
                    <span className="append">incluye IVA</span>
                  </p>
                )}
                <Button
                  disabled={disabled && selected !== packageTier.servicePackageTypeCode}
                  icon={<CkCaretDownIcon />}
                  className={`toogle-details ${
                    expand === packageTier.servicePackageTypeCode ? "expanded" : ""
                  }`}
                  onClick={() => toogleDetails(packageTier.servicePackageTypeCode)}
                >
                  ¿Qué incluye?
                </Button>
              </div>
              <div
                className={`tier-detail ${
                  expand === packageTier.servicePackageTypeCode ? "expanded" : ""
                }`}
              >
                {packageTier?.servicePackageTypeDetails && packageTier?.servicePackageTypeDetails}

                {packageTier?.servicePackageTypeComments && (
                  <p className="gray-container">
                    {packageTier?.servicePackageTypeComments.replaceAll("<br />", "<br/>")
                      .split("<br/>")
                      .map((line) => `${line}\n\n`)}
                  </p>
                )}
              </div>
            </div>
          ))}
        </UserOptions>
        {disabled === false && (
          <InteractionOptions
            delay={"1p5"}
            onGoBack={() => setDisabled(true)}
          />
        )}
      </>
    );
  };
  /* 
  ==========================================================================
                          /Choose Package and tiers
  ========================================================================== 
  */
  /* 
  ==========================================================================
                                Choose Service
  ========================================================================== 
  */
  /**
   * Request user to choose a service
   */
  const RequestUserService: FC<{}> = () => {
    const [selected, setSelected] = useState<WorkshopService>();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [availableServices, setAvailableServices] = useState<WorkshopService[]>([]);

    useEffect(() => {
      selected !== undefined && setDisabled(true);
    }, [selected]);

    useEffect(() => {
      setAvailableServices(contactData.current.availableServices);
    }, []);

    return (
      <>
        <FlowMessage
          delay={"p5"}
          label={"¿Que tipo de reparación necesitas?"}
        />
        <UserOptions flexDirection="column" delay={"1"}>
          {availableServices.map((code) => (
            <Button
              key={code.workshopServiceId}
              disabled={disabled}
              className={selected && code.workshopServiceId === selected.workshopServiceId ? "active" : ""}
              onClick={() => {
                setSelected(code);
                contactData.current.selectedService = code;
                pushInteraction(<RequestUserQuestion />);
              }}
            >
              {code.workshopServiceName}
            </Button>
          ))}
        </UserOptions>
        {disabled === false && (
          <InteractionOptions
            delay={"1p5"}
            onGoBack={() => setDisabled(true)}
          />
        )}
      </>
    );
  };

  /* 
  ==========================================================================
                                /Choose Service
  ========================================================================== 
  */
  /* 
  ==========================================================================
                              User have question
  ========================================================================== 
  */
  /**
   * Ask user if have question
   */
  const RequestIfHaveAnyQuestion: FC<{}> = () => {
    const [selected, setSelected] = useState<"si" | "no" | "">("");
    const [disabled, setDisabled] = useState<boolean>(false);

    useEffect(() => {
      selected !== "" && setDisabled(true);
    }, [selected]);

    return (
      <>
        <FlowMessage
          delay={"p5"}
          label={"¿Tienes alguna duda respecto al precio o contenido?"}
        />
        <UserOptions flexDirection="row" delay={"1"}>
          <Button
            disabled={disabled}
            className={[...(selected === "si" ? ["active"] : [])].join(" ")}
            onClick={() => {
              setSelected("si");
              pushInteraction(<RequestUserQuestion />);
            }}
          >
            Si
          </Button>
          <Button
            disabled={disabled}
            className={[...(selected === "no" ? ["active"] : [])].join(" ")}
            onClick={() => {
              setSelected("no");
              pushInteraction(<NoQuestion />);
            }}
          >
            No
          </Button>
        </UserOptions>
        {disabled === false && (
          <InteractionOptions
            delay={"1p5"}
            onGoBack={() => setDisabled(true)}
          />
        )}
      </>
    );
  };

  /**
   * Show message in case user dont have any question
   */
  const NoQuestion: FC<{}> = () => {
    const [disabled, setDisabled] = useState<boolean>(false);
    const [url, setUrl] = useState<string>("");
    useEffect(() => {
      setUrl(`/taller/${contactData.current.workshop.id}/agendar`);
    }, []);
    return (
      <>
        <FlowMessage
          delay={"p5"}
          label={
            <>
              Esperamos haber respondido tus dudas ¿Te gustaría agendar una cita
              para este mantenimiento? <Link to={url}>¡Hazlo aquí!</Link>
            </>
          }
        />
        {disabled === false && (
          <InteractionOptions
            delay={"1p5"}
            onGoBack={() => setDisabled(true)}
          />
        )}
      </>
    );
  };
  /**
   * Request users for their messages, or what they want to ask, for maintainance
   * Note: states defined outside the component does not trigger the useEffects
   *       thats why we are using custom events
   */
  const RequestUserQuestion: FC<{}> = ({}) => {
    const isDisabled = useRef<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [response, setResponse] = useState<string>();
    const [criteria, setCriteria] = useState<TCriteria>();

    const callNextStep = () => {
      pushInteraction(
        contactData.current.userVehicles.length > 0 ? (
          <ChooseVehicleFromFavorites />
        ) : (
          <RequestVehicleData />
        )
      );
    };

    // This function will listen the userInput when is triggered
    const handleUserInputEvent = (event: CustomEvent) => {
      if (disabled || isDisabled.current === true) return;
      triggerLoading(true);
      const userResponse = event.detail;
      disableUserInput();
      contactData.current.message = userResponse.toString();
      setResponse(userResponse);
      triggerLoading(false);
      callNextStep();
    };

    const disableUserInput = () => {
      isDisabled.current = true;
      setDisabled(true);
      removeUserInputListener(handleUserInputEvent);
      setExpectedUserInput(undefined);
    };

    useEffect(() => {
      setExpectedUserInput("message");
      addUserInputListener(handleUserInputEvent);
      setCriteria(contactData.current.criteria);
      return () => {
        removeUserInputListener(handleUserInputEvent);
      };
    }, []);

    return (
      <>
        <FlowMessage
          delay={"p5"}
          label={
            criteria === "packages"
              ? "Escribe tu duda en el campo de mensaje"
              : "Describe el problema de tu vehículo en el campo de mensaje. También puedes adjuntar imágenes, audio o video."
          }
        />
        {disabled === false && (
          <InteractionOptions delay={"1"} onGoBack={() => disableUserInput()} />
        )}

        {response && (
          <UserMessage
            label={
              criteria === "packages"
                ? "Duda sobre mantenimiento:"
                : "Problema con tu vehículo:"
            }
            text={response}
          />
        )}
      </>
    );
  };
  /*
  ==========================================================================
                              /User have question
  ========================================================================== 
  */
  /* 
  ==========================================================================
                              Vehicle data
  ========================================================================== 
  */
  /**
   * Request the user to choose between the favorite vehicles
   * Note: states defined outside the component does not trigger the useEffects
   *       thats why we are using custom events
   */
  const ChooseVehicleFromFavorites: FC<{}> = ({}) => {
    const [selected, setSelected] = useState<number>();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [vehicles, setVehicles] = useState<IVehicle[]>([]);

    useEffect(() => {
      selected !== undefined && setDisabled(true);
    }, [selected]);

    useEffect(() => {
      setVehicles(contactData.current.userVehicles);
    }, []);

    return (
      <>
        <FlowMessage
          delay={"p5"}
          label={"¿Para qué vehículo sería el servicio?"}
        />
        <UserOptions flexDirection="column" delay={"1"}>
          {vehicles.map((vehicle) => (
            <Button
              key={vehicle.endConsumerVehicleId}
              disabled={disabled}
              className={
                vehicle.endConsumerVehicleId === selected ? "active" : ""
              }
              onClick={() => {
                setSelected(vehicle.endConsumerVehicleId);
                contactData.current.vehicle = vehicle;
                contactData.current.vehicleText = "";
                pushInteraction(<RequestUserName />);
              }}
            >
              {vehicle.nickname !== ""
                ? vehicle.nickname
                : [vehicle.brandName, vehicle.year, vehicle.modelName].join(
                    " "
                  )}
            </Button>
          ))}
          <Button
            disabled={disabled}
            className={selected === -1 ? "active" : ""}
            onClick={() => {
              setSelected(-1);
              contactData.current.vehicle = undefined;
              pushInteraction(<RequestVehicleData />);
            }}
          >
            Otro
          </Button>
        </UserOptions>
        {disabled === false && (
          <InteractionOptions
            delay={"1p5"}
            onGoBack={() => setDisabled(true)}
            onSkip={() => {
              setDisabled(true);
              contactData.current.vehicle = undefined;
              contactData.current.vehicleText = "";
              pushInteraction(<RequestUserName />);
            }}
          />
        )}
      </>
    );
  };
  /**
   * Request users to input the vehicle data
   * Note: states defined outside the component does not trigger the useEffects
   *       thats why we are using custom events
   */
  const RequestVehicleData: FC<{}> = () => {
    const [haveFavorites, setHaveFavorites] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [response, setResponse] = useState<string>();

    const callNextStep = () => pushInteraction(<RequestUserName />);

    const disableUserInput = () => {
      setDisabled(true);
      removeUserInputListener(handleUserInputEvent);
      setExpectedUserInput(undefined);
    };

    // This function will listen the userInput when is triggered
    const handleUserInputEvent = (event: CustomEvent) => {
      triggerLoading(true);
      const userResponse = event.detail;
      disableUserInput();
      contactData.current.vehicleText = userResponse.toString();
      contactData.current.vehicle = undefined;
      setResponse(userResponse);
      triggerLoading(false);
      callNextStep();
    };

    useEffect(() => {
      setExpectedUserInput("vehicle");
      addUserInputListener(handleUserInputEvent);
      setHaveFavorites(contactData.current.userVehicles.length > 0);
      return () => {
        removeUserInputListener(handleUserInputEvent);
      };
    }, []);

    return (
      <>
        <FlowMessage
          delay={"p5"}
          label={
            haveFavorites
              ? "Escribe los datos de tu vehículo"
              : "¿Para qué vehículo sería el servicio?"
          }
        />
        {disabled === false && (
          <InteractionOptions
            delay={"1"}
            onGoBack={() => disableUserInput()}
            onSkip={() => {
              disableUserInput();
              contactData.current.vehicle = undefined;
              contactData.current.vehicleText = "";
              pushInteraction(<RequestUserName />);
            }}
          />
        )}

        {response && <UserMessage label={response} />}
      </>
    );
  };
  /* 
  ==========================================================================
                              /Vehicle data
  ========================================================================== 
  */

  /* 
  ==========================================================================
                              User Data Interactions
  ========================================================================== 
  */
  /**
   * Request user name
   * Note: states defined outside the component does not trigger the useEffects
   *       thats why we are using custom events
   */
  const RequestUserName: FC<{}> = ({}) => {
    const [showInteraction, setShowInteraction] = useState<boolean>(
      user ? false : true
    );
    const [response, setResponse] = useState<string>();
    const [disabled, setDisabled] = useState<boolean>(false);

    const callNextStep = () => pushInteraction(<RequestUserEmail />);

    const enableUserInput = () => {
      setShowInteraction(true);
      setExpectedUserInput("name");
      addUserInputListener(handleUserInputEvent);
      scrollBottom();
    };

    const disableUserInput = () => {
      setDisabled(true);
      removeUserInputListener(handleUserInputEvent);
      setExpectedUserInput(undefined);
    };

    // This function will listen the userInput when is triggered
    const handleUserInputEvent = (event: CustomEvent) => {
      triggerLoading(true);
      const userResponse = event.detail;
      disableUserInput();
      // Set data in contactData object
      const splitedUserResponse = userResponse.toString().split(" ");
      const name = splitedUserResponse[0] || "";
      const lastName =
        splitedUserResponse.length > 1
          ? splitedUserResponse.slice(1, splitedUserResponse.length).join(" ")
          : "";
      contactData.current.user = {
        ...(contactData.current.user
          ? contactData.current.user
          : {
              email: "",
              phone: "",
              id: "",
            }),
        name,
        lastName,
      };
      setResponse(userResponse);
      triggerLoading(false);
      callNextStep();
    };

    useEffect(() => {
      // First, lets check if user exist
      if (user) {
        // if exists, lets take the name from the user session information
        triggerLoading(true);
        // lets see if user have a valid name
        if (user.name !== "" && user.lastName !== "") {
          contactData.current.user = {
            ...(contactData.current.user
              ? contactData.current.user
              : {
                  email: "",
                  phone: "",
                  id: "",
                }),
            name: user.name,
            lastName: user.lastName,
          };
          callNextStep();
        } else {
          enableUserInput();
        }
        triggerLoading(false);
      } else {
        // else, we have to request the user name
        enableUserInput();
      }
      return () => {
        removeUserInputListener(handleUserInputEvent);
      };
    }, []);

    if (showInteraction === false) return null;

    return (
      <>
        {showInteraction && (
          <>
            <FlowMessage
              delay="p5"
              label={
                "Necesitamos tus datos de contacto para enviarte la respuesta a tu duda. En primer lugar, cual es tu nombre completo?"
              }
            />
            {disabled === false && (
              <InteractionOptions
                delay="1"
                onGoBack={() => disableUserInput()}
              />
            )}
            {response && <UserMessage label={response} />}
          </>
        )}
      </>
    );
  };

  /**
   * Request user name
   * Note: states defined outside the component does not trigger the useEffects
   *       thats why we are using custom events
   */
  const RequestUserEmail: FC<{}> = ({}) => {
    const [showInteraction, setShowInteraction] = useState<boolean>(
      user ? false : true
    );
    const [response, setResponse] = useState<string>();
    const [disabled, setDisabled] = useState<boolean>(false);

    const callNextStep = () => pushInteraction(<RequestUserPhone />);

    const enableUserInput = () => {
      setShowInteraction(true);
      setExpectedUserInput("email");
      addUserInputListener(handleUserInputEvent);
    };

    const disableUserInput = () => {
      setDisabled(true);
      removeUserInputListener(handleUserInputEvent);
      setExpectedUserInput(undefined);
    };

    // This function will listen the userInput when is triggered
    const handleUserInputEvent = (event: CustomEvent) => {
      triggerLoading(true);
      const userResponse = event.detail;
      disableUserInput();
      // Set data in contactData object
      contactData.current.user = {
        ...(contactData.current.user
          ? contactData.current.user
          : {
              name: "",
              lastName: "",
              phone: "",
              id: "",
            }),
        email: userResponse.toString(),
      };
      setResponse(userResponse);
      triggerLoading(false);
      callNextStep();
    };

    useEffect(() => {
      // First, lets check if user exist
      if (user) {
        // if exists, lets take the name from the user session information
        triggerLoading(true);
        // lets see if user have a valid email
        if (user.email !== "") {
          contactData.current.user = {
            ...(contactData.current.user
              ? contactData.current.user
              : {
                  name: "",
                  lastName: "",
                  phone: "",
                  id: "",
                }),
            email: user.email,
          };
          callNextStep();
        } else {
          enableUserInput();
        }
        triggerLoading(false);
      } else {
        // else, we have to request the user name
        enableUserInput();
      }
      return () => {
        removeUserInputListener(handleUserInputEvent);
      };
    }, []);

    if (showInteraction === false) return null;

    return (
      <>
        {showInteraction && (
          <>
            <FlowMessage delay="p5" label={"¿Cuál es tu correo electrónico?"} />
            {disabled === false && (
              <InteractionOptions
                delay="1"
                onGoBack={() => disableUserInput()}
              />
            )}
            {response && <UserMessage label={response} />}
          </>
        )}
      </>
    );
  };

  /**
   * Request user phone number
   * Note: states defined outside the component does not trigger the useEffects
   *       thats why we are using custom events
   */
  const RequestUserPhone: FC<{}> = ({}) => {
    const [showInteraction, setShowInteraction] = useState<boolean>(
      user ? false : true
    );
    const [response, setResponse] = useState<string>();
    const [disabled, setDisabled] = useState<boolean>(false);

    const callNextStep = () => pushInteraction(<RequestUserConsent />);

    const enableUserInput = () => {
      setShowInteraction(true);
      setExpectedUserInput("telephone");
      addUserInputListener(handleUserInputEvent);
    };

    const disableUserInput = () => {
      setDisabled(true);
      removeUserInputListener(handleUserInputEvent);
      setExpectedUserInput(undefined);
    };

    // This function will listen the userInput when is triggered
    const handleUserInputEvent = (event: CustomEvent) => {
      triggerLoading(true);
      const userResponse = event.detail;
      disableUserInput();
      // Set data in contactData object
      contactData.current.user = {
        ...(contactData.current.user
          ? contactData.current.user
          : {
              name: "",
              lastName: "",
              email: "",
              id: "",
            }),
        phone: userResponse.toString(),
      };
      setResponse(userResponse);
      triggerLoading(false);
      callNextStep();
    };

    useEffect(() => {
      // First, lets check if user exist
      if (user) {
        // if exists, lets take the name from the user session information
        triggerLoading(true);
        // lets see if user have a valid email
        if (user.phone !== "") {
          contactData.current.user = {
            ...(contactData.current.user
              ? contactData.current.user
              : {
                  name: "",
                  lastName: "",
                  email: "",
                  id: "",
                }),
            phone: user.phone,
          };
          callNextStep();
        } else {
          enableUserInput();
        }
        triggerLoading(false);
      } else {
        // else, we have to request the user name
        enableUserInput();
      }
      return () => {
        removeUserInputListener(handleUserInputEvent);
      };
    }, []);

    useEffect(() => {
      if (disabled) {
        removeUserInputListener(handleUserInputEvent);
        setExpectedUserInput(undefined);
      }
    }, [disabled]);

    if (showInteraction === false) return null;

    return (
      <>
        {showInteraction && (
          <>
            <FlowMessage delay="p5" label={"¿Cuál es tu teléfono celular?"} />
            {disabled === false && (
              <InteractionOptions
                delay="1"
                onGoBack={() => disableUserInput()}
              />
            )}
            {response && <UserMessage label={response} />}
          </>
        )}
      </>
    );
  };

  /**
   * Request for user to accept terms and conditions
   * Note: states defined outside the component does not trigger the useEffects
   *       thats why we are using custom events
   */
  const RequestUserConsent: FC<{}> = ({}) => {
    const [showInteraction, setShowInteraction] = useState<boolean>(
      user ? false : true
    );
    const [checked, setChecked] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);

    const enableUserInput = () => {
      setShowInteraction(true);
      setExpectedUserInput("terms");
      addUserInputListener(handleUserInputEvent);
    };

    const disableUserInput = () => {
      setDisabled(true);
      removeUserInputListener(handleUserInputEvent);
      // setExpectedUserInput(undefined);
    };

    // This function will listen the userInput when is triggered
    const handleUserInputEvent = (event: CustomEvent) => {
      triggerLoading(true);
      const userResponse = event.detail;
      disableUserInput();
      userResponse === true && postNewMessage();
      //triggerLoading(false);
    };

    useEffect(() => {
      // First, lets check if user exist
      if (user) {
        // If exists, lets process the request
        postNewMessage();
      } else {
        enableUserInput();
      }
      return () => {
        removeUserInputListener(handleUserInputEvent);
      };
    }, []);

    useEffect(() => setEnableSend(checked), [checked]);

    if (showInteraction === false) return null;

    return (
      <>
        {showInteraction && (
          <>
            <UserOptions>
              <div className="terms-checkbox-wrapper">
                <CkCheckbox
                  noMargin
                  checkboxProps={{
                    className: "terms-checkbox",
                    checked: checked,
                    onChange: (e) => setChecked(e.target.checked),
                    disabled: disabled,
                  }}
                >
                  <p>
                    He leído y estoy de acuerdo con los
                    <Link
                      to="/terminosycondiciones"
                      target="_blank"
                      className="terms-link"
                      type="link"
                    >
                      <span>T y C</span>
                    </Link>
                  </p>
                </CkCheckbox>
              </div>
            </UserOptions>
            {disabled === false && (
              <InteractionOptions onGoBack={() => disableUserInput()} />
            )}
          </>
        )}
      </>
    );
  };
  /* 
  ==========================================================================
                              /User Data Interactions
  ========================================================================== 
  */
  /* 
  ==========================================================================
                              Message Sent
  ========================================================================== 
  */
  const MessageOutcome: FC<{
    label: string;
  }> = ({ label }) => <FlowMessage delay="p5" label={label} />;
  /* 
  ==========================================================================
                              /Message Sent
  ========================================================================== 
  */

  /**
   * Effects
   */
  useEffect(() => {
    if (displayAs === undefined || isVisible === false) return;
    const payload = {
      id: displayAs === "page" ? workshopId : workshopIdProp,
      coordinates: `19.4326077,-99.133208`, // CDMX By default, this value does not affect the overall user experience in this component
      userTimeZone: new Date().getTimezoneOffset().toString(),
    };

    if (payload.id === undefined) {
      displayAs === "page" && history.replace("/404");
      return;
    }
    getWorkshop(payload);
  }, [isVisible, displayAs, workshopIdProp]);

  useEffect(() => {
    contactData.current.fileList = fileList;
  }, [fileList]);

  useEffect(() => {
    blobService.current = new BlobServiceClient(`${account}?${sasToken}`);
    containerClient.current =
      blobService.current.getContainerClient(containerName);
  }, [sasToken]);

  useEffect(() => {
    if (isVisible) {
      // If Layout is mobilhub, redirect to page
      if (globalLayout === "mobilhub" && displayAs !== "page") {
        workshop && history.push(`/taller/${workshopIdProp}/contacto`);
      }
      // workshop && updatePaddings({ scrollMessages: false });
      user && getVehicles();
    }
  }, [globalLayout, isVisible, workshop]);

  /**
   * Components
   */
  const LoadingInteractionDisplay = () => {
    const [interactionLoading, setInteractionLoading] =
      useState<boolean>(false);

    const handleInteractionLoadingEvent = (event) => {
      setInteractionLoading(event.detail === true);
    };

    useEffect(() => {
      addInteractionLoadingEvent(handleInteractionLoadingEvent);
      return () => {
        removeInteractionLoadingEvent(handleInteractionLoadingEvent);
      };
    }, []);

    if (interactionLoading === false) return null;
    return (
      <div className="loading-interaction animate__animated animate__fadeIn">
        <div className="dot-flashing-container">
          <div className="dot-flashing" />
        </div>
      </div>
    );
  };

  const InteractionsWrapper = () => (
    <div className="interations-wrapper" ref={isVisible ? wrapperRef : null}>
      {interactions.map((interaction, key) => (
        <React.Fragment key={key}>{interaction}</React.Fragment>
      ))}
      {LoadingInteractionDisplay()}
    </div>
  );

  const FlowMessage = ({
    delay,
    label,
  }: {
    delay?: TAnimationsDelay;
    label: string | ReactNode;
  }) => {
    return (
      <div
        className={`flow-message animate__animated ${
          delay ? `animate__delay-${delay}s` : ""
        } animate__fadeIn`}
      >
        <p>{label && label}</p>
      </div>
    );
  };

  const UserMessage = ({
    delay,
    label,
    text,
  }: {
    delay?: TAnimationsDelay;
    label?: string;
    text?: string;
  }) => (
    <div
      className={`user-message animate__animated ${
        delay ? `animate__delay-${delay}s` : ""
      } animate__fadeIn`}
    >
      <p>{label && label}</p>
      <p>{text && text}</p>
    </div>
  );

  const UserOptions = ({
    delay,
    flexDirection = "row",
    children,
  }: {
    delay?: TAnimationsDelay;
    flexDirection?: "row" | "column";
    children: any;
  }) => (
    <div
      className={`user-options ${flexDirection} animate__animated ${
        delay ? `animate__delay-${delay}s` : ""
      } animate__fadeIn`}
    >
      {children}
    </div>
  );

  const InteractionOptions = ({
    delay,
    onGoBack,
    onSkip,
  }: {
    delay?: TAnimationsDelay;
    onGoBack?: Function;
    onSkip?: Function;
  }) => (
    <div
      className={`interaction-options ${
        delay ? `animate__delay-${delay}s` : ""
      } animate__animated animate__fadeIn`}
    >
      {onGoBack && (
        <Button
          icon={<CkReturnCurveIcon />}
          className="go-back"
          ghost
          onClick={() => {
            onGoBack && onGoBack();
            previousInteration();
          }}
        >
          Pregunta anterior
        </Button>
      )}
      {onSkip && (
        <Button
          icon={<CkContinueCurveIcon />}
          className="skip"
          ghost
          onClick={() => {
            onSkip && onSkip();
          }}
        >
          Saltar pregunta
        </Button>
      )}
    </div>
  );

  const Header = () => {
    return (
      <div className="contact-header" ref={isVisible ? headerRef : null}>
        <div className="upper">
          <figure>
            {workshop && (
              <>
                {workshop?.workshopPhotos?.filter(
                  (photo) => photo.description === "LOGO"
                ).length > 0 ? (
                  <Image
                    className="image-modal"
                    src={
                      workshop &&
                      workshop.workshopPhotos &&
                      workshop &&
                      workshop.workshopPhotos.length > 0
                        ? `${
                            workshop &&
                            workshop.workshopPhotos &&
                            workshop?.workshopPhotos?.filter(
                              (photo) => photo.description === "LOGO"
                            )[0].photoUrl
                          }?${sasToken}`
                        : ""
                    }
                    preview={false}
                    alt={`Logo de ${workshop?.name}`}
                  />
                ) : (
                  <Image
                    className="image-modal"
                    src={VehiclePlaceholder}
                    preview={false}
                    alt={`Logo de ${workshop?.name}`}
                  />
                )}
                <figcaption>
                  <p>
                    <span>Contacta</span> a
                  </p>
                  <p>
                    {[
                      ...(workshop.name && workshop.name.length > 0
                        ? [workshop.name]
                        : []),
                      ...(workshop.sucursalName &&
                      workshop.sucursalName.length > 0
                        ? [workshop.sucursalName]
                        : []),
                    ].join(" - ")}
                  </p>
                </figcaption>
              </>
            )}
          </figure>
          <div className="close-button-container-cw">
            <Button
              className="--for-desktop"
              icon={<CKCloseIcon />}
              size="large"
              shape="circle"
              onClick={close}
              ghost
            />
            <Button
              className="--for-mobile"
              type="text"
              shape="circle"
              onClick={close}
              icon={<CloseOutlined />}
              ghost
            />
          </div>
        </div>
        <CkButton
          icon={<CkReloadIcon />}
          type="link"
          className="reset-chat"
          onClick={() => {
            pushInteraction(<SelectContactType />, {
              reset: true,
            });
            setFileList([]);
          }}
        >
          Reiniciar chat
        </CkButton>
      </div>
    );
  };

  const Footer = () => {
    // if (isVisible === false) return null;
    return (
      <div className="contact-footer" ref={isVisible ? footerRef : null}>
        {fileList.length > 0 && expectedUserInput === "message" && (
          <LocaleAttachmentsMap
            fileList={fileList}
            setFileList={setFileList}
            expectedUserInput={expectedUserInput}
          />
        )}
        <UserInput
          enableSend={enableSend}
          fileList={fileList}
          setFileList={setFileList}
          setAudioVisible={setAudioVisible}
          expectedUserInput={expectedUserInput}
          setExpectedUserInput={setExpectedUserInput}
          messageForm={messageForm}
          user={user}
        />
      </div>
    );
  };

  const Component = () => (
    <div className="contact-component" ref={componentWrapper}>
      <WheelLoader
        mode="cover-container"
        spinning={workshop === undefined || interactions.length === 0}
      />
      {/* <Header /> */}
      {Header()}
      {InteractionsWrapper()}
      {Footer()}
      {/* Drawer with the conversation  */}
      {/* Modal for video upload  */}
      <VideoPlayerModal videoUrl={videoUrl} setVideoUrl={setVideoUrl} />
      {/* Modal for video upload  */}
      {/* Modal for audio upload  */}
      <AudioModal
        fileList={fileList}
        setFileList={setFileList}
        audioVisible={audioVisible}
        setAudioVisible={setAudioVisible}
        user={user}
      />
      <CreateAccountCTA
        setRequestResume={setRequestResume}
        requestResume={requestResume}
        onCancel={close}
      />
      {/* Modal for audio upload  */}
    </div>
  );

  if (displayAs === "page")
    return (
      <section className="page-container workshop-contact workshop-contact-page">
        {Component()}
      </section>
    );

  return (
    <Drawer
      className={"workshop-contact workshop-contact-drawer"}
      closable={false}
      footer={null}
      placement="bottom"
      open={isVisible}
      getContainer={getContainer}
    >
      {Component()}
    </Drawer>
  );
};

export default WorkshopContact;
